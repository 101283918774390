import React , { useState, useEffect } from 'react'
import Back from './common/Back'
import { useDispatch } from 'react-redux';
import { login } from '../redux/userSlice';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';

const Login = () => {

    const dispatch = useDispatch();

    const baseUrl=process.env.REACT_APP_API_URL;


    const [formData, setFormData] = useState({
      clave:"",
      email: "",
      opcion: "login",

    });
  
 
    const navigate = useNavigate();

  
    const validateForm = () => {
  
      const { clave, email } = formData;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      if ( !clave || !email) {
        return 'Todos los campos de Informacion de pago son obligatorios';
      }
  
      if (!emailRegex.test(email)) {
        return 'El correo electrónico no es válido';
      }
  
      return null;
  
    };


    
  const handleSubmit = () => {

    const error = validateForm();

    if (error) {
      alert(error);
      return;
    }


    // e.preventDefault();
     axios.post(baseUrl, formData)
       .then(response => {

         console.log('response ', response);

         if (response.data.msg=="success"){

            //const userData = { username, password, email: 'user@example.com' }; // Datos de ejemplo
            dispatch(login(response.data.datauser));
             //console.log('Formulario almacenado exito');
             alert('Inicio de sesion exitoso!!');

             // Aquí puedes manejar la respuesta del servidor
             navigate(`/`);
         }
         else{

             console.log('Error:', response);
             alert('Se genero un Error');
             
         }
 
       })
       .catch(error => {
         console.error('Error al enviar el formulario:', error);
         // Aquí puedes manejar el error
       });
   };


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("value: "+ name );

    setFormData({
      ...formData,
      [name]: value
      
      });

  };



    return (
        <div>
            <Back title='Login'/>
            <div className='w-9/12 text-center bg-gray-300 py-10 px-6 mx-4 rounded-lg sm:mx-20 md:mx-28'>
                <h4 className='text-1xl lg:text-3xl font-semibold' style={{color: '#81c408'}}>Inicio de sesion</h4>

                <div className='lg:flex-row lg:justify-center mx-2 md:mx-9 xl:mx-24'>

                    <form action='' className='lg:w-11/12 lg:justify-center'>
                        <input className='w-full outline-none my-3 py-4 px-4 rounded-lg'  type="email"  id="email"
             name="email"
             value={formData.email}
             onChange={handleChange}  placeholder='Email'/>

                        <input className='w-full outline-none my-3 py-4 px-4 rounded-lg'  type="text"  id="clave"
             name="clave"
             value={formData.clave}
             onChange={handleChange}  placeholder='Clave de acceso' />

                        <button type="button" onClick={() => handleSubmit()} className='order uppercase px-4 py-4 border border-orange-400 rounded-lg my-6 mx-auto text-center block w-full text-md font-semibold duration-500'>Guardar pedido</button>
                        
                        </form>
                   
                </div>
            </div>
        </div>
    )
}

export default Login
