import React , { useState, useEffect } from 'react'
import Slider from "react-slick";

import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/CartSlice';
import axios from 'axios';

/*var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};
*/
const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 7000,
    cssEase: "linear"
  };

const Hero = () => {
    const dispatch = useDispatch();


    const [data, setData]= useState([]);


    const baseUrl=process.env.REACT_APP_API_URL;
   
    
  const peticionGetOffer=async()=>{

    await axios.post(baseUrl, {
        opcion: "productosEnOfertas"
        
      })
    
    .then((json) => { 

       // console.log("data json");
        //console.log(json.data.Productos);
        setData(json.data.Productos);
       // catalogosGet();
       
  

   }).catch(error=>{
      console.log(error);
    })
  }


  
  useEffect(()=>{
    console.log("antes de get :");
    peticionGetOffer();


  }, [])


    return (
        <>
            <div>
                <div className='overlay'>
                    <div className='md:container border rounded-md md:mx-auto flex flex-col justify-between lg:flex-row'>
                        
                        <div className='w-full md:w-9/12 md:mx-auto border rounded-md m-auto lg:w-12/12 lg:h-8/12'>
                            <Slider {...settings}>
                            {data.map((val) => {
                        return (
                               
                                <div className='w-1/2 h-50 md:w-9/12 flex flex-row bg-[#f2f2f2] relative'>
                                    
                                    <img className='border rounded-md w-3/5 h-full' src={val.imagen} alt="" />
                                    <span className='imgBuy absolute top-1/4 left-3/4  w-1/4 md:w-40 text-xs md:text-base font-bold'>
                                   
                                   <div className='relative'>
                               <img className='w-full relative' src='../img/esp_oferta1.png' alt="" />
                           </div>
                           </span>
                                    <span className='img absolute top-2/4 left-3/4 py-3 text-xs md:text-4xl font-bold px-10 text-[#fff]'>
                                    
                                    <h5>-{ val.descuento }% </h5>
                                    <h4>{ val.nombre } </h4>
                                    </span>
                                   
                                    <span className='imgBuy absolute top-3/4 left-3/4  w-1/4 md:w-50 text-xs md:text-base font-bold'>
                                    
                                    <div className='border rounded-full border-orange-400 py-2 px-2 bg-[#29a329] lg:px-2 text-white hover:text-white hover:bg-[#1a1aff]'  >
                                    <span className='text-base pr-4 lg:pr-1 ' ><i className='fa fa-shopping-cart'></i></span>
                                    <button onClick={() => dispatch(addToCart(val))}  >Agregar a carrito</button>
                                </div>
                                    </span>
                                   
                                </div>

)
})}

                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero
