import React from 'react'
import Back from './common/Back'

const Contact = () => {
    return (
        <div>
            <Back title='Contacto'/>
            <div className='m-auto text-center bg-gray-100 py-10 px-6 mx-4 rounded-lg sm:mx-20 md:mx-28'>
                <h1 className='text-2xl lg:text-5xl font-semibold' style={{color: '#81c408'}}>Contactenos</h1>
                 <div className=" rounded w-10/12 h-96 mx-auto my-8">
                    
                    <iframe className="rounded w-full h-full"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124838.77246075461!2d-86.2584606!3d12.097659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f71560dd907880b%3A0x6c5ef4a2144f4c6a!2sManagua!5e0!3m2!1ses!2sni!4v1720296977291!5m2!1ses!2sni" 
                     loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>
                <div className='lg:flex lg:justify-between mx-2 md:mx-9 xl:mx-24'>
                    <form action='' className='lg:w-6/12'>
                        <input className='w-full outline-none my-3 py-4 px-4 rounded-lg' type="text" placeholder='Nombre'/>
                        <input className='w-full outline-none my-3 py-4 px-4 rounded-lg' type="text" placeholder='Email'/>
                        <textarea className='w-full outline-none my-3 py-4 px-4 rounded-lg' cols="30" rows="5" placeholder='Mensaje'></textarea>
                        <input className='w-full outline-none my-3 py-4 px-4 font-semibold border border-orange-400 rounded-lg bg-white duration-500 input-contact' type="submit" />
                    </form>
                    <div className='lg:w-5/12'>
                        <div className='bg-white my-4 rounded-lg flex items-center py-6 px-2'>
                            <span className='text-3xl ml-4' style={{color: '#81c408'}}><i className='fa fa-location-dot'></i></span>
                            <div className='flex flex-col items-start justify-start ml-6'>
                                <h2 className='text-xl font-semibold' style={{color: '#45595b'}}>Ubicacion</h2>
                                <span className='w-fit' style={{color: '#45595b'}}>Managua, Nicaragua</span>
                            </div>
                        </div>
                        <div className='bg-white my-4 rounded-lg flex items-center py-6 px-2'>
                            <span className='text-3xl ml-4' style={{color: '#81c408'}}><i className='fa fa-envelope'></i></span>
                            <div className='flex flex-col items-start justify-start ml-6'>
                                <h2 className='text-xl font-semibold' style={{color: '#45595b'}}>Correo</h2>
                                <span className='w-fit' style={{color: '#45595b'}}>frankball4@gmail.com</span>
                            </div>
                        </div>
                        <div className='bg-white my-4 rounded-lg flex items-center py-6 px-2'>
                            <span className='text-3xl ml-4' style={{color: '#81c408'}}><i className='fa fa-phone'></i></span>
                            <div className='flex flex-col items-start justify-start ml-6'>
                                <h2 className='text-xl font-semibold' style={{color: '#45595b'}}>Telefone</h2>
                                <span className='w-fit' style={{color: '#45595b'}}>(+505) 7633 6310</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
