import React , { useState, useEffect } from 'react'
import Back from './common/Back'

import { useSelector, useDispatch } from 'react-redux';
import { addToCart,  removeFromCart ,clearItem ,clearCart  } from '../redux/CartSlice';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Alert, IconButton } from '@mui/material';
import axios from 'axios';
import SnackbarNotification from './common/SnackBarNotification';

const Checkout = () => {

  const dispatch = useDispatch();
  const items = useSelector(state => state.cart.items);
  const subtotal = useSelector(state => state.cart.subtotal);
  const total = useSelector(state => state.cart.total);

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [typePay, setTypePay] = useState(1);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [estado, setEstado] = useState("");

  const baseUrl=process.env.REACT_APP_API_URL;


  const [formData, setFormData] = useState({
    name: '',
    tipoPago:1,
    products: [],
    lastname:"",
    dir:"",
    dir2: "",
    city:"",
    phone: "",
    opcion: "guardarOrden",
  });

  console.log("items ", items);



  const [selectedCheckbox, setSelectedCheckbox] = useState("direct");

    const handleCheckboxChange = (checkboxId, typep) => {
    console.log("checkbox ", typep)
    setSelectedCheckbox(checkboxId);
    setTypePay(typep);

    setFormData({ ...formData, 
      tipoPago: typep
  
       });

  };




  const validateForm = () => {

    const { name, tipoPago, lastname, dir, city, phone, email } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!name || !tipoPago || !lastname || !dir || !city || !phone || !email) {
      return 'Todos los campos del formulario Informacion de pago son obligatorios';
    }

    if (!emailRegex.test(email)) {
      return 'El correo electrónico no es válido';
    }

    return null;

  };




  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("value: "+ name );


    setFormData({
      ...formData,
      [name]: value
      
      });

  };




 // creates a paypal order
 const createOrder = (data, actions) => {
  return actions.order
    .create({
      purchase_units: [
        {
          description: "Sunflower",
          amount: {
            currency_code: "USD",
            value: 5,
          },
        },
      ],
      // not needed if a shipping address is actually needed
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    })
    .then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });
};



 // check Approval
 const onApprove = (data, actions) => {
  return actions.order.capture().then(function (details) {

    const { payer, status } = details;
    console.log(details);
    setSuccess(true);
  });


};



//capture likely error
const onError = (data, actions) => {
  setErrorMessage("An Error occured with your payment ");
};



const handleSubmit = () => {
  // e.preventDefault();
const error = validateForm();

    if (error) {
      //alert(error);
setMensaje(error);
setEstado("error");
setOpen(true);


      return;
    }

   axios.post(baseUrl, formData)
     .then(response => {
       console.log('response ', response.data);
       if (response.data.msg=="success"){

           console.log('Formulario almacenado exito', typePay);
           setMensaje("Orden almacenada con exito!!");
           setEstado("success");
           setOpen(true);

          if (typePay===3){
             setShow(true);
           }
           else{
            setShow(false);
          }

           // Aquí puedes manejar la respuesta del servidor
       }
       else{
           console.log('Error:', response);
           setMensaje('Se genero un Error.');
setEstado("error");
setOpen(true);
       }

     })
     .catch(error => {
       //console.error('Error al enviar el formulario:', error);
       setMensaje(error);
setEstado("error");
setOpen(true);
       // Aquí puedes manejar el error
     });
 };


 
  
 useEffect(()=>{

  setFormData({ ...formData, 
    products: items,
    

     });



}, [])

const handleClick = () => {
  setOpen(true);
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};


    return (
      <>
      
      <SnackbarNotification open={open}  handleClose={handleClose}  severity={estado}  msg={mensaje}/>
      <PayPalScriptProvider 
      options={{
        "client-id":'AQwhyi6yYLBmyOQLp-3Dc7i_CuMsNt3n58XIGX_19nvVN3f8elbZwHtiPR1pTsOKSbjEOWiqvwgn9xO0',
        "locale" :"es_ES",
        "currency": "USD",
      }}
    >
      <div>
        
        <Back title='Pagar'/>
        <div className='mx-5 sm:mx-16'>
        
          <h1 className='text-3xl font-semibold mb-3 lg:text-3xl lg:flex ' style={{color: '#2d5986'}}>Informacion de pago </h1>
                
          <div className='lg:flex lg:justify-between'>
            <form className='lg:w-6/12 lg:mr-10' action="" style={{color: '#45595b'}}>

              <div>
                <label>Nombres<sup className=' relative top-2 text-4xl'>*</sup></label>
                <input className='w-full border border-gray-300 outline-green-300 rounded-lg px-2 py-2 mt-2' type="text" placeholder='Nombre' id="name"
                                 name="name"
                                 value={formData.name}
                                 onChange={handleChange} />
              </div>

              <div>
                <label>Apellidos<sup className=' relative top-2 text-4xl'>*</sup></label>
                <input className='w-full border border-gray-300 outline-green-300 rounded-lg px-2 py-2 mt-2' type="text" placeholder='Apellidos' id="lastname"
                                 name="lastname"
                                 value={formData.lastname}
                                 onChange={handleChange}/>
              </div>
             
              <div>
                <label>Direccion<sup className=' relative top-2 text-4xl'>*</sup></label>
                <input className='w-full border border-gray-300 outline-green-300 rounded-lg px-2 py-2 mt-2' type="text" placeholder=''
                placeholder='direccion' id="dir"
                name="dir"
                value={formData.dir}
                onChange={handleChange} />
              </div>

              <div>
                <label>Ciudad<sup className=' relative top-2 text-4xl'>*</sup></label>
                <input className='w-full border border-gray-300 outline-green-300 rounded-lg px-2 py-2 mt-2' type="text" placeholder='Ciudad' id="city"
                                 name="city"
                                 value={ formData.city }
                                 onChange={handleChange}/>

              </div>
              
              <div>
                <label>Telefono<sup className=' relative top-2 text-4xl'>*</sup></label>
                <input className='w-full border border-gray-300 outline-green-300 rounded-lg px-2 py-2 mt-2' type="text"
                placeholder='Telefono' id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange} />
              </div>

              <div>
                <label>Email<sup className=' relative top-2 text-4xl'>*</sup></label>
                <input className='w-full border border-gray-300 outline-green-300 rounded-lg px-2 py-2 mt-2' type="text"
               type="email"  id="email"
               name="email"
               value={formData.email}
               onChange={handleChange}  placeholder='Email' />
              </div>

             
              <hr />
              <div className='my-4'>
                <input type="checkbox" id='ship'/>
                <label className='ml-2' htmlFor='ship'>Enviar a una direccion diferente?</label>
              </div>

              <textarea className='w-full border border-gray-300 outline-green-300 rounded-lg px-2 py-2 mt-2' cols="30" rows="10" type="text"  id="dir2"
                                 name="dir2"
                                 value={formData.dir2}
                                 onChange={handleChange}   placeholder=' (Opcional)'></textarea>

            </form>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-10">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                          <th scope="col" className="px-6 py-3">
                              Productos
                          </th>
                          <th scope="col" className="px-6 py-3">
                              Nombre
                          </th>
                          <th scope="col" className="px-6 py-3">
                              Precio
                          </th>
                          <th scope="col" className="px-6 py-3">
                              Cantidad
                          </th>
                          <th scope="col" className="px-6 py-3">
                              Total
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                  {items.map(item => (
                      <tr key={item.id} className="border-b">
                          <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <img className="img-cart rounded-full" src={item.imagen} alt={item.nombre} />
                          </td>
                          <td className="px-6 py-4">
                          {item.nombre}
                          </td>
                          <td className="px-6 py-4">
                          {item.moneda} {item.price}
                          </td>
                          <td className="px-6 py-4">
                          {item.quantity}
                          </td>
                          <td className="px-6 py-4">
                          ${parseFloat(item.price * item.quantity).toFixed(2)}
                          </td>
                      </tr>
                       ))}
                      
                  </tbody>
              </table>
              <div className='flex font-semibold bg-gray-200 justify-end pr-10 my-8' style={{color: '#45595b'}}>
                <h3>Subtotal: </h3>
                <span className='ml-20'>{subtotal.toFixed(2)}</span>
              </div>
              <hr />
             
              <div className='flex font-semibold bg-gray-200 justify-end pr-10 my-8' style={{color: '#45595b'}}>
                <h3 className='uppercase'>Total: </h3>
                <span className='ml-20'> {(total + 3).toFixed(2)} </span>
              </div>
              <hr />
              <div className='my-10 px-4' style={{color: '#45595b'}}>
                <div className='flex mb-4'>
                  <input type="checkbox" id='direct' checked={selectedCheckbox === 'direct'}
          onChange={() => handleCheckboxChange('direct', 1)}/>
                  <label className='ml-2' htmlFor='direct'>Transferencia Bancaria</label>
                </div>
                <p className='w-10/12'>Haga su pago directo a la cuenta bancaria de la Empresa .</p>
              </div>
              <hr />
              
              <div className='my-6 px-4' style={{color: '#45595b'}}>
                <input type="checkbox" id='cash' checked={selectedCheckbox === 'cash'}
          onChange={() => handleCheckboxChange('cash',2)}/>
                <label className='ml-2' htmlFor='cash'>Efectivo al entregar</label>
                <p className='w-10/12'>Paga cuando se entrega la orden en la direccion solicitada.</p>
              </div>
              <hr />
              <div className='my-6 px-4' style={{color: '#45595b'}}>

                <input type="checkbox" id='pay' checked={selectedCheckbox === 'pay'}
          onChange={() => handleCheckboxChange('pay',3)}/>

                <label className='ml-2' htmlFor='pay'>Paypal</label>
                <p className='w-10/12'>Realiza el pago utilizando la plataforma Paypal o tarjeta de credito.</p>
              </div>
              <hr />
              <div className='px-4'>
                <button type="button" onClick={() => handleSubmit()} className='order uppercase px-4 py-4 border border-orange-400 rounded-lg my-6 mx-auto text-center block w-full text-md font-semibold duration-500'>Guardar pedido</button>
                
             
             {show ? (
         <PayPalButtons
           style={{ layout: "vertical" }}
           createOrder={createOrder}
           onApprove={onApprove}
         />
       ) : null}

              </div>
            </div>
          </div>
        </div>
         
       

      </div>
      </PayPalScriptProvider>
      </>
    )
}

export default Checkout
