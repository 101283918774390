import React, { useState } from 'react';
import { Button, Snackbar, Alert } from '@mui/material';

const SnackbarNotification = ({ open, handleClose , severity, msg }) => {
  

 
  return (
    <div>
      
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {msg}
      </Alert>
    </Snackbar>
    </div>
  );
};

export default SnackbarNotification;
