import React , { useState, useEffect } from "react";
import Back from './common/Back'
import { shop_feature ,fruits } from '../data/Data'
import Slider from '@mui/material/Slider';
import { useDispatch } from 'react-redux';
import { addToCart } from '../redux/CartSlice';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Shop = () => {
    const dispatch = useDispatch();

    const [data, setData]= useState([]);
    const [dataTemp, setDataTemp]= useState([]);

    const [categories, setCategories]= useState([]);
    const [dataO, setDataO]= useState([]);

    const baseUrl=process.env.REACT_APP_API_URL;
    
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState('');
    
  const handleProductClick = (id) => {
    navigate(`/detalle/${id}`);
  };



  const peticionGet=async()=>{
    await axios.post(baseUrl, {
        opcion: "productos"
        
      })
    
    .then((json) => { 

       // console.log("data json");
        console.log(json.data.Productos);
        setData(json.data.Productos);
        setDataTemp(json.data.Productos);
       // catalogosGet();
       categoriesGet();

   }).catch(error=>{
      console.log(error);
    })
  }




  const categoriesGet=async()=>{
    await axios.post(baseUrl, {
        opcion: "ObtenerCategorias"
        
      }
      )
    
    .then((json) => { 

       
        setCategories(json.data.Categorias);
        //setCategorias(json.data.Categorias);
        peticionGetOffer();

   }).catch(error=>{
      console.log(error);
    })
  }


  
  const handleChange = (e) => {

    const { name, value } = e.target;

    setDataTemp([]);
    var text = value;
    text = text.toString();
    console.log(text);
    if (text !== undefined && text !== null) {
        
    if (text.length>1){
      const filteredData = data.filter((item) =>
        item.nombre.toLowerCase().includes(text.toString().toLowerCase())
      );
    
     
        setDataTemp(filteredData);

    }
    }
        setSearchQuery(text);


  };




  
  const handleChangeSel = (e) => {

    const { name, value } = e.target;

    if (value=="desc"){
         data.sort(compareByNameDesc);
    }

    if (value=="asc"){
        data.sort(compareByNameAsc);
   }

        setDataTemp(data);
    


  };




  const handleChangeShowCat = (id) => {


    setDataTemp([]);
    

      const filteredData = data.filter((item) => item.idCategoria === id);
    
     
        setDataTemp(filteredData);

   


  };




  const peticionGetOffer=async()=>{

    await axios.post(baseUrl, {
        opcion: "productosEnOfertas"
        
      })
    
    .then((json) => { 

       // console.log("data json");
        //console.log(json.data.Productos);
        setDataO(json.data.Productos);
       // catalogosGet();
       
  

   }).catch(error=>{
      console.log(error);
    })
  }




// Función de comparación para ordenar por nombre de forma ascendente
function compareByNameAsc(a, b) {
    if (a.nombre < b.nombre) {
      return -1;
    }
    if (a.nombre > b.nombre) {
      return 1;
    }
    return 0;
  }




  // Función de comparación para ordenar por nombre de forma descendente
function compareByNameDesc(a, b) {
    if (a.nombre < b.nombre) {
      return 1;
    }
    if (a.nombre > b.nombre) {
      return -1;
    }
    return 0;
  }



    useEffect(()=>{
        console.log("antes de get :");
        peticionGet();
    
    
      }, [])


    return (
        <div>
            <Back title='Productos'/>
            <div className='sm:mx-20 lg:flex 2xl:mx-40'>
                <div className='mx-6'>
                    <h1 className='text-2xl font-semibold' style={{color: '#45595b'}}>Productos</h1>
                    <div className='w-full relative mx-auto my-6'>
                        <input className='w-full py-3 px-3 border border-gray-200 rounded-lg outline-none' type="text" 
                        value={searchQuery}
                        onChange={ handleChange } 
                        id="search"
                                 name="search"
                        placeholder='Que busca??' />
                        <span className='py-3 px-4 border absolute top-0 right-0 bg-gray-200  rounded-lg' style={{color: '#45595b'}}><i className='fa fa-search'></i></span>
                    </div>
                    <div>
                        <div className='bg-gray-100 w-full py-3 px-4 flex justify-between rounded-lg my-8'>
                            <label style={{color:'#45595b'}} for="filter">Ordenar:</label>
                            <select onChange={ handleChangeSel } className='bg-gray-100' name="filter" id="filter">
                                <option value="0">Seleccione</option>
                                <option value="asc">Ascendente</option>
                                <option value="desc">Descendente</option>
                                
                            </select>
                        </div>
                        <h2 className='text-xl font-semibold' style={{color: '#45595b'}}>Categorias (Filtrar)</h2>
                        <ul className='flex flex-col'>
                        {categories.map((valCat) => {
                        return (

                            <div key={valCat.id}  className='flex justify-between leading-10'>
                            <button onClick={() => handleChangeShowCat(valCat.id)}  >    <li className='text-blue-600/75 duration-500'><i class="fa fa-list" aria-hidden="true"></i><span className='ml-2'>{valCat.nombre}</span></li>
                            </button>
                            </div>
                            
                            )
            })}
                        </ul>
                        <div className='my-8'>
                            <h2 className='text-xl font-semibold' style={{color: '#45595b'}}>PRECIO</h2>
                            <div>
                                <Slider style={{color: '#81c408'}} defaultValue={0} aria-label="Default" min={0} max={500} valueLabelDisplay="auto" />
                            </div>
                        </div>
                       
                        <h2 className='text-xl font-semibold mt-6' style={{color: '#45595b'}}>OFERTAS</h2>
                        <div>
                            {dataO.slice(0,3).map((val) => {
                                return (
                                    <div className='flex flex-start items-center my-3' key={val.id}>
                                        <div>
                                            <img className='w-28 h-24' src={val.imagen} alt="" />
                                        </div>
                                        <div className='ml-4 justify-center items-center'>
                                            <h4 className='text-md font-semibold' style={{color: '#45595b'}}>{val.nombre}</h4>
                                            <ul className='flex'>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#45595b'}}>{val.star}</li>
                                            </ul>
                                            <div className='lg:flex lg:flex-col'>
                                                <span className='text-xl font-bold' style={{color: '#45595b'}}>{val.moneda} {val.price}</span>
                                                <span className='text-xl font-bold ml-2 ' style={{color: '#dc3545 '}}>-{val.descuento}%</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                       
                    </div>
                </div>
                <div className='grid grid-cols-1 gap-2 mx-5 mt-10 md:grid-cols-2 xl:grid-cols-3 xl:gap-x-3 xl:gap-y-0'>
                    {dataTemp.slice(0,9).map((val) => {
                        return (
                          
<div key={val.id} className='px-1 box border rounded-lg border-grey-800 mx-auto mb-6 w-full'>
<button onClick={() => handleProductClick(val.id)}  >
                            <div className='w-full relative overflow-clip'>
                                <img className='hover:scale-150 duration-500 w-full rounded-lg' src={val.imagen} alt="" />
                                <span className='absolute top-3 left-3 text-blue text-lg py-1 px-4  border  border-[#0f3d0f] ' style={{backgroundColor: '#adebad'}}>{val.categoria}</span>
                            </div>
                            <div className='text-center'>
                                <h3 className='text-1xl mt-3 font-semibold' style={{color: '#161c1d'}}>{val.nombre}</h3>
                                <p className='leading-normal font-normal my-4 w-10/12 mx-auto' style={{color: '#020e1ccf'}}>{val.descripcion}</p>
                            </div>
                            </button>
                            <div className='flex justify-between items-center mb-8 mx-6 xl:mx-3'>
                                <p className='text-base font-semibold' style={{color: '#0039e6'}}>{val.moneda} {val.price} </p>
                                <div className='border rounded-full border-orange-400 py-2 px-2 bg-[#29a329] lg:px-2 text-white hover:text-white hover:bg-[#1a1aff]'  >
                                    <span className='text-base pr-4 lg:pr-1 ' ><i className='fa fa-shopping-cart'></i></span>
                                    <button onClick={() => dispatch(addToCart(val))} className='text-xs ' >Agregar a Carrito</button>
                                </div>
                            </div>
                        </div>
                        
                        )
                    })}
                </div>
            </div>
           
        </div>
    )
}

export default Shop
