import React , { useState, useEffect } from 'react'
import { bsetseller } from '../../data/Data'
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/CartSlice';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';

const Bestseller = () => {

    const dispatch = useDispatch();

    const [data, setData]= useState([]);
 
    const navigate = useNavigate();

    const baseUrl=process.env.REACT_APP_API_URL;


  const peticionGetBest=async()=>{
    await axios.post(baseUrl, {
        opcion: "productosMasVendidos"
        
      })
    
    .then((json) => { 

       // console.log("data json");
        //console.log(json.data.Productos);
        setData(json.data.Productos);
       // catalogosGet();
  

   }).catch(error=>{
      console.log(error);
    })
  }



  useEffect(()=>{
    console.log("antes de get :");
    peticionGetBest();


  }, [])


    
  const handleProductClick = (id) => {
    navigate(`/detalle/${id}`);
  };


  

    return (
        <div className='mt-40'>
            <div className='lg:mx-20 '>
                <h2 className='text-2xl md:text-3xl xl:text-5xl font-bold mx-auto text-center' style={{color: '#2d5986'}}> Productos Mas vendidos</h2>
                <p className='mx-4 text-center mt-4 w-auto ' style={{color: '#45595b'}}>Los siguientes son los articulos mas vendidos en nuestra tienda:</p>
                <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3'>
                    {data.map((val) => {
                        return (
                            <div key={val.id} className='flex justify-evenly items-center mt-8 mx-4 sm:mx-20 py-8 md:justify-around md:mx-32 lg:mx-4 ' style={{backgroundColor: '#eff2f5'}}>
                                
                                <div className='img-sell lg:ml-12 xl:ml-2'>
                                    <img className='w-full h-full img-best hover:scale-150' src={val.imagen} alt="" />
                                </div>
                                <div className=''>
                                    <h5 className='py-2 mb-4 text-lg font-semibold w-20' style={{color: '#45595b'}}>{val.nombre}</h5>
                                    <p className='leading-normal font-normal my-4 w-10/12 mx-auto' style={{color: '#020e1ccf'}}>{val.categoria}</p>
                          
                                    <ul className='flex my-3'>
                                        <li style={{color: '#81c408'}}>{val.star}</li>
                                        <li style={{color: '#81c408'}}>{val.star}</li>
                                        <li style={{color: '#81c408'}}>{val.star}</li>
                                        <li style={{color: '#81c408'}}>{val.star}</li>
                                        <li style={{color: '#45595b'}}>{val.star}</li>
                                    </ul>
                                    <p className='text-lg font-semibold ' style={{color: '#45595b'}}> {val.moneda} {val.price} </p>
                                    <div className='border rounded-full border-orange-400 py-2 px-2 bg-[#29a329] lg:px-2 text-white hover:text-white hover:bg-[#1a1aff]'  >
                                    <span className='text-base pr-4 lg:pr-1 ' ><i className='fa fa-shopping-cart'></i></span>
                                    <button onClick={() => dispatch(addToCart(val))} className='text-xs ' >Agregar a Carrito</button>
                                </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Bestseller
