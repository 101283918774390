import React, { useState, useEffect } from 'react'
import { fruits } from '../../data/Data'
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/CartSlice';

import Slider from "react-slick";

import axios from 'axios';

import { useNavigate } from 'react-router-dom';

const FruitsSlide = () => {

    const dispatch = useDispatch();
    
    const navigate = useNavigate();

    const settings = {
        
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 8000,
    cssEase: "linear"

    };

      const [data, setData]= useState([]);

      const baseUrl=process.env.REACT_APP_API_URL;
      
    const peticionGet=async()=>{
      await axios.post(baseUrl, {
          opcion: "productos"
          
        })
      
      .then((json) => { 
  
          // console.log("data json");
          //console.log(json.data.Productos);
          setData(json.data.Productos);
         // catalogosGet();
  
     }).catch(error=>{
        console.log(error);
      })
    }
  
    /*
    const catalogosGet=async()=>{
      await axios.post(baseUrl, {
          opcion: "catalogos"
          
        }
        )
      
      .then((json) => { 
  
          console.log("data json");
          console.log(json.data);
          setCategorias(json.data.Categorias);
          //setCategorias(json.data.Categorias);
         
   
  
     }).catch(error=>{
        console.log(error);
      })

    }
      */
  
      
  const handleProductClick = (id) => {
    navigate(`/detalle/${id}`);
  };



  
      useEffect(()=>{
          console.log("antes de get :");
          peticionGet();
      
      }, [])
  

    return (
        <div className='mt-40 container ml-auto mr-auto'>
            <div className='flex flex-col mb-10 mx-10 md:mx-20 lg:flex lg:justify-center lg:flex-row lg:mx-10 lg:gap-10'>
                <h1 className='text-3xl font-semibold mb-3 lg:text-3xl lg:flex lg:justify-center lg:items-center' style={{color: '#2d5986'}}>Nuestros Productos</h1>
                
            </div>
            <div className='w-11/12 md:w-12/12 md:mx-auto m-auto lg:w-12/12 lg:h-12/12'>
            <div className="slider-container">
            
            <Slider {...settings}>
        
            {data.map((val) => {
                        return (
                            
            <div key={val.id} className='px-1 box border rounded-lg border-grey-800 mx-auto mb-6 w-full'>
  
                      <button  onClick={() => handleProductClick(val.id)}  >

                            <div className='w-full relative overflow-clip'>
                                <img className='hover:scale-150 duration-500 w-full rounded-lg' src={val.imagen} alt="" />
                                <span className='absolute top-3 left-3 text-blue text-lg py-1 px-4  border  border-[#0f3d0f] ' style={{backgroundColor: '#adebad'}}>{val.categoria}</span>
                            </div>

                            <div className='text-center'>
                                <h3 className='text-1xl mt-3 font-semibold' style={{color: '#161c1d'}}>{val.nombre}</h3>
                                <p className='leading-normal font-normal my-4 w-10/12 mx-auto' style={{color: '#020e1ccf'}}>{val.descripcion}</p>
                            </div>

                      </button>

                            <div className='flex justify-between items-center mb-8 mx-6 xl:mx-3'>
                                <p className='text-base font-semibold' style={{color: '#0039e6'}}> {val.moneda} {val.price} </p>
                                <div className='border rounded-full border-orange-400 py-2 px-2 bg-[#29a329] lg:px-2 text-white hover:text-white hover:bg-[#1a1aff]'  >
                                    <span className='text-base pr-4 lg:pr-1 ' ><i className='fa fa-shopping-cart'></i></span>
                                    <button onClick={() => dispatch(addToCart(val))} className='text-xs ' >Agregar a Carrito</button>
                                </div>
                            </div>
                        </div>
                        )
              })}

 </Slider>
                 </div>
            </div>
        </div>
    )
}

export default FruitsSlide;
