import React from 'react';

const WhatsAppButton = ({ phoneNumber, message }) => {
  // URL de WhatsApp con el número de teléfono y un mensaje predeterminado
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

  return (
    <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
      <button className="fixed bottom-4 right-4 flex items-center bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full shadow-lg">
        <img 
          src="https://cdn-icons-png.flaticon.com/128/174/174879.png" 
          alt="WhatsApp" 
          className="w-6 h-6 mr-2" 
        />
        Contactar
      </button>
    </a>
  );
};

export default WhatsAppButton;