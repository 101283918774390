import React , { useState, useEffect }  from 'react'
import Back from './common/Back'

import { useNavigate } from 'react-router-dom';
  
import axios from 'axios';

const Register = () => {
     
  const navigate = useNavigate();

  const baseUrl=process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    name: '',
    lastname:"",
    clave:"",
    email: "",
    dir:"",
    city:"",
    phone: "",
    opcion: "crearUsuario",
  });


  const validateForm = () => {

    const { name, lastname, clave, dir, city, phone, email } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!name || !clave || !lastname || !dir || !city || !phone || !email) {
      return 'Todos los campos de Informacion de pago son obligatorios';
    }

    if (!emailRegex.test(email)) {
      return 'El correo electrónico no es válido';
    }

    return null;

  };

  const handleSubmit = () => {

    const error = validateForm();

    if (error) {
      alert(error);
      return;
    }


    // e.preventDefault();
     axios.post(baseUrl, formData)
       .then(response => {

       //  console.log('response ', response);

         if (response.data.msg=="success"){

             //console.log('Formulario almacenado exito');
             alert('Usuario creado con exito');
             // Aquí puedes manejar la respuesta del servidor
             navigate(`/login`);
         }
         else{

             console.log('Error:', response);
             alert('Se genero un Error');

         }
 
       })
       .catch(error => {
         console.error('Error al enviar el formulario:', error);
         // Aquí puedes manejar el error
       });
   };


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("value: "+ name );


    setFormData({
      ...formData,
      [name]: value
      
      });

  };




    return (
        <div>
            <Back title='Registro'/>
            <div className='w-9/12 text-center bg-gray-300 py-10 px-6 mx-4 rounded-lg sm:mx-20 md:mx-28'>
                <h1 className='text-2xl lg:text-5xl font-semibold' style={{color: '#81c408'}}>Registro de usuario</h1>

                <form action='' className='lg:w-11/12  lg:justify-center'>
            <div>
                <input className='w-full outline-none my-3 py-4 px-4 rounded-lg' type="text" placeholder='Nombre' id="name"
                                 name="name"
                                 value={formData.name}
                                 onChange={handleChange} />
              </div>

              <div>
               
                <input className='w-full border border-gray-300 outline-green-300 rounded-lg my-3 py-4 px-4' type="text" placeholder='Apellidos' id="lastname"
                                 name="lastname"
                                 value={formData.lastname}
                                 onChange={handleChange}/>
              </div>
             
              <div>
              
              <input className='w-full border border-gray-300 outline-green-300 rounded-lg my-3 py-4 px-4' type="text"
             type="email"  id="email"
             name="email"
             value={formData.email}
             onChange={handleChange}  placeholder='Email' />
            </div>
  
            <div>
              
            <input className='w-full border border-gray-300 outline-green-300 rounded-lg my-3 py-4 px-4' type="text"
             type="text"  id="clave"
             name="clave"
             value={formData.clave}
             onChange={handleChange}  placeholder='Clave de acceso' />
            </div>

            <div>
              
              <input className='w-full border border-gray-300 outline-green-300 rounded-lg my-3 py-4 px-4' type="text"
              placeholder='Telefono' id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange} />
            </div>
            <div>
              
              <input className='w-full border border-gray-300 outline-green-300 rounded-lg my-3 py-4 px-4' type="text" placeholder='Ciudad' id="city"
                               name="city"
                               value={ formData.city }
                               onChange={handleChange}/>

            </div>
              <div>
               
              

<textarea className='w-full border-b-2 outline-none border-gray-200 my-3 py-4 px-4 rounded-b-md' id="dir"
          name="dir"
          value={formData.dir}
          onChange={handleChange} placeholder='Direccion' cols="30" rows="2"></textarea>
              </div>

             
              
              

              
              <div>
              <button type="button" onClick={() => handleSubmit()} className='order uppercase px-4 py-4 border border-orange-400 rounded-lg my-6 mx-auto text-center block w-full text-md font-semibold duration-500'>Guardar pedido</button>
              </div>
            </form>
                   
                
            </div>
        </div>
    )
}

export default Register
