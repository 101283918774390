// src/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { logout } from '../../../redux/userSlice';

const NavbarResp = () => {

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [bar, setBar] = useState(false);
  const totalItems = useSelector(state => state.cart.totalItems);
  const user = useSelector((state) => state.user.user);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);


  return (

    <nav className="fixed w-full bg-white text-gray-800 shadow-md z-50">
        <div className='md:container md:mx-auto hidden lg:flex lg:justify-between text-white text-sm main p-1 top box-border '>
                <div className='flex w-7/12'>
                    <div>
                        <span className='fas fa-map-marker-alt secondary pr-3 pl-2'></span>
                        <span className='hover:cursor-pointer'>Managua, Nicaragua </span>
                    </div>
                    <div>
                        <span className="fas fa-envelope secondary pr-3 pl-4"></span>
                        <span className='hover:cursor-pointer'>frankball4@gmail.com</span>
                    </div>
                </div>
                <div className='flex w-4/12'>
                <div>
                {isLoggedIn ? <span className='fas fa-user secondary pr-3 pl-2'></span> : <i class="fa fa-sign-in secondary pr-3 pl-2" aria-hidden="true"></i> }
                        {!isLoggedIn ? <span className='hover:cursor-pointer'><Link to='/login'>Iniciar sesion </Link></span> : "Hola "+ user.nombre + "    " }
                    </div>
                    <div>
                    {!isLoggedIn ? <span className='fas fa-user secondary pr-3 pl-2'></span>: <i className="fa fa-sign-out secondary pr-3 pl-6" aria-hidden="true"></i> }
                        {!isLoggedIn ? <span className='hover:cursor-pointer'><Link to='/registro'>Registrarse</Link> </span>:""}
                        {isLoggedIn ? <button onClick={() => dispatch(logout())}>Cerrar sesion</button> :""}
                    </div>
                </div>
            </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
          <div className='text-[#e69900] text-2xl sm:text-3xl font-extrabold logo_containerspan'><Link to='/'>DTodo<span className=" text-[#e69900] ">Chic</span></Link></div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
            <ul className={`xl:flex xl:justify-center xl:items-center relative ${bar ? 'bar-menu flex flex-col bg-white right-0 relative' : 'hidden xl:flex xl:flex-row'}`}>
                    <li className='p-4 hover:bg-gray-800 rounded-xl m-2 cursor-pointer duration-300 hover:text-white'><Link to='/'>Inicio</Link></li>
                    <li className='p-4 hover:bg-gray-800 rounded-xl m-2 cursor-pointer duration-300 hover:text-white'><Link to='/productos'>Produtos</Link></li>
                    <li className='p-4 hover:bg-gray-800 rounded-xl m-2 cursor-pointer duration-300 hover:text-white'><Link to='/detalle/1'>Detalle de Productos</Link></li>
                    <li className='p-4 hover:bg-gray-800 rounded-xl m-2 cursor-pointer duration-300 hover:text-white nav-hov relative pages'><Link>Pagos</Link>
                        <i className='fa fa-angle-down pl-1 hover:cursor-pointer'></i>
                        <ul className='absolute inside rounded-md top-10 bg-gray-200 xl:py-2 pl-3 pr-8'>
                            <li className='p-1 li-hov hover:bg-gray-800 rounded-xl m-2 cursor-pointer duration-300 hover:text-white'><Link to='/carrito'>Carrito</Link></li>
                            <li className='p-1 li-hov hover:bg-gray-800 rounded-xl m-2 cursor-pointer duration-300 hover:text-white'><Link to='/pagar'>Pagar</Link></li>
                            
                        </ul>
                    </li>
                    <li className='p-4 hover:bg-gray-800 rounded-xl m-2 cursor-pointer duration-300 hover:text-white'><Link to='/contacto'>Contacto</Link></li>
                    <div className={`xl:pl-20 xl:flex ${bar ? 'flex bg-white' : 'hidden'}`}>
                        <span className='fa fa-search main-text p-5 mr-2 mt-2 w-10 h-10 border search search-hov flex justify-center items-center hover:cursor-pointer'></span>
                        <Link to='/carrito' className='fa fa-shopping-cart fa-2x main-text p-3 relative icon-hov'>
                                <span className='absolute top-2 right-0 w-6 h-6 text-xs search flex justify-center items-center bord text-green-600'>{totalItems}</span>
                        </Link>
                       
                    </div>
                </ul>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a href="#home" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">Home</a>
            <a href="#about" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">About</a>
            <a href="#services" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">Services</a>
            <a href="#contact" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">Contact</a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavbarResp;
