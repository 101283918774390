import React , { useState, useEffect } from 'react'

import axios from 'axios';

const Service = () => {

    const [data, setData]= useState([]);


    const baseUrl=process.env.REACT_APP_API_URL;
   

  const peticionGetMarcas=async()=>{
    await axios.post(baseUrl, {
        opcion: "marcasRandom"
        
      })
    
    .then((json) => { 

            console.log(json.data.Marcas);
        setData(json.data.Marcas);
       // catalogosGet();
       
   }).catch(error=>{
      console.log(error);
    })
  }


  
  useEffect(()=>{
    console.log("antes de get :");
    peticionGetMarcas();


  }, [])




    return (
        <>
        <h2 className='text-2xl md:text-3xl xl:text-4xl font-bold mx-auto text-center' style={{color: '#2d5986'}}> Nuestras Marcas</h2>
   
        <div className='mt-40 mx-4 md:grid md:grid-cols-2 md:mx-24 grid gap-4 sm:grid sm:mx-20 lg:grid-cols-3 lg:mx-24 xl:mx-40'>
              
{data.map((val) => {
                        return (
            <div className='box-service border-2 border-orange-400 rounded-xl relative'>
                <div className='relative max-w-full z-40'>
                    <img src={val.imagen} className='w-full rounded-t-xl'  alt="" />
                    <div className='info-service relative bottom-20 py-8 px-12 w-9/12 sm:w-7/12 md:w-9/12  max-w-full rounded-lg text-center' style={{backgroundColor: '#f4f6f8'}}>
                    <h4 className='text-white text-xl font-semibold' style={{color: '#81c408'}}>{val.nombre}</h4>
                        <p className='text-lg lg:text-1xl lg:font-semibold' style={{color: '#45595b'}}>{val.descripcion}</p>
                    </div>
                </div>
                <div className='w-full h-64 absolute bottom-0 rounded-xl'style={{backgroundColor: '#ffb524'}}></div>
            </div>
             )
            })}

        </div>
        </>
    )
}

export default Service
