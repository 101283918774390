import React , { useState, useEffect }  from 'react'

import { useSelector, useDispatch } from 'react-redux';
import { addToCart,  removeFromCart ,clearItem ,clearCart  } from '../../redux/CartSlice';
import axios from 'axios';

const Banner = () => {

    const [data, setData]= useState([]);

    const dispatch = useDispatch();
    const items = useSelector(state => state.cart.items);
    const subtotal = useSelector(state => state.cart.subtotal);
    const total = useSelector(state => state.cart.total);

    const baseUrl=process.env.REACT_APP_API_URL;
    const [cadena, setCadena] = useState("");


  const peticionGetNew=async()=>{
    await axios.post(baseUrl, {
        opcion: "productoNuevo"
        
      })
    
    .then((json) => { 
//console.log(json.data.Producto["precio"]);
        setData(json.data.Producto);
       // catalogosGet();
        setCadena(json.data.Producto["precio"]);
       // console.log(cadena);

   }).catch(error=>{
      console.log(error);
    })
  }


  
  useEffect(()=>{
    console.log("antes de get :");
    peticionGetNew();


  }, [])


    return (
        <div className='mt-40 py-20' style={{backgroundColor: '#e6ffe6'}}>
            <div className='mx-10 lg:flex lg:justify-between lg:items-center xl:mx-24'>
                <div className='mx-auto'>
                    <h1 className='text-4xl lg:text-6xl  font-bold'style={{color: '#3333ff'}}>Productos en Tendencia</h1>
                    <h2 className='text-4xl lg:text-6xl font-semibold my-5' style={{color: '#45595b'}}>en nuestras tiendas</h2>
                    <p className='md:text-lg' style={{color: '#45595b'}}> Todo en Tecnologia  </p>
                    <div className='buy py-3 px-12 border-2 rounded-full w-fit font-semibold hover:text-white hover:bg-[#1a1aff] duration-300 mt-4 mb-10' style={{color: '#fff'}}>
                    <div className=' py-2 px-2  lg:px-5 text-white '  >
                                    <span className='text-base pr-4 lg:pr-1 ' ><i className='fa fa-shopping-cart'></i></span>
                                    <button onClick={() => dispatch(addToCart(data))} className='text-xs ' >Agregar  a Carrito</button>
                        </div>
                        </div>
                        

                </div>
                <div className='relative md:mx-20'>
                    <div className='w-fit lg:w-96 xl:w-auto'>
                        <img className='w-full' src={data["imagen"]} alt="" />
                    </div>
                    <div className='absolute top-0 left-0 bg-white rounded-full w-32 h-32 flex justify-center items-center'>
                        <span className='text-4xl font-bold' style={{color: '#45595b'}}>{cadena.split('/***/')[1]==1?"$ ":"C$ "} {cadena.split('/***/')[0]} </span>
                        <div className='flex flex-col py-10'>
                            <span className='text-1l font-bold' style={{color: '#45595b'}}></span>
                            <span className='text-lg font-semibold' style={{color: '#45595b'}}>Und</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
