import React , { useState, useEffect }  from 'react'
import Back from './common/Back'
import { shop_feature, bsetseller } from '../data/Data'
import { useDispatch } from 'react-redux';
import { addToCart } from '../redux/CartSlice';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ShopDetail = () => {
    const dispatch = useDispatch();
    const [data, setData]= useState([]);
    const [mainImage, setMainImage]= useState("");
    const [dataCom, setDataCom]= useState([]);

    const [dataO, setDataO]= useState([]);
    const [dataR, setDataR]= useState([]);

    const [nombreComm, setNombreComm]= useState("");

    const [emailComm, setEmailComm]= useState("");
    const [mensaje, setMensaje]= useState("");

    const baseUrl=process.env.REACT_APP_API_URL;
    
    const { id } = useParams();
    const [formData, setFormData] = useState({
        idProd:'',
        name: '',
        email: '',
        message: '',
        opcion: "guardarComentario"
      });


  const peticionGetOffer=async()=>{

    await axios.post(baseUrl, {
        opcion: "productosEnOfertas"
        
      })
    .then((json) => { 

        setDataO(json.data.Productos);
      

   }).catch(error=>{
      console.log(error);
    })
  }


  
  const peticionGetComments=async(idProd)=>{

    await axios.post(baseUrl, {
        idProd: idProd,
        opcion: "getComments"
        
      })
    
    .then((json) => { 
        
        setDataCom(json.data.Commentarios);
       // catalogosGet();

   }).catch(error=>{
      console.log(error);
    })
  }



  const peticionGetProductsRelations=async(idcat)=>{

    await axios.post(baseUrl, {
        idCat: idcat,
        opcion: "productos"
        
      })
    
    .then((json) => { 

       // console.log("data json");
        //console.log(json.data.Productos);
        setDataR(json.data.Productos);
       // catalogosGet();
       
  

   }).catch(error=>{
      console.log(error);
    })
  }



    
  const getDetail=async(idProd)=>{

 // console.log("data id detail "+id);

    await axios.post(baseUrl, {
        id: idProd,
        opcion: "detalleProducto"
        
      })
    
    .then((json) => { 

       // console.log("data json");
       // console.log(json.data.Producto);
        setData(json.data.Producto);
        setMainImage(json.data.Producto["imagen"]);

      
      
       peticionGetProductsRelations(json.data.Producto["idCategoria"]);
       peticionGetComments(idProd);

   }).catch(error=>{
      console.log(error);
    })
  }



  
  useEffect(()=>{
    //console.log("antes de get :");
    getDetail(id);
 // catalogosGet();
 peticionGetOffer();


  }, [])



// Función para manejar el evento onMouseEnter
const handleMouseEnter = (image) => {
    setMainImage(image);
  };


  
// Función para manejar el evento onMouseEnter
const handleShowDetail = (idProd) => {
    //setMainImage(image);
    getDetail(idProd);

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("value: "+ name );

    setFormData({
      ...formData,
      [name]: value,
      ["idProd"]:id  
      });

  };

  const handleSubmit = () => {
   // e.preventDefault();
    axios.post(baseUrl, formData)
      .then(response => {
        console.log('response ', response);
        if (response.msg=="success"){
            console.log('Formulario almacenado exito');
            // Aquí puedes manejar la respuesta del servidor
        }
        else{
            console.log('Error:', response);
        }

      })
      .catch(error => {
        console.error('Error al enviar el formulario:', error);
        // Aquí puedes manejar el error
      });
  };

    return (
        <div >
            <Back title='Detalle Producto'/>
            <h2 className='text-2xl md:text-3xl xl:text-3xl font-bold  text-center' style={{color: '#2d5986'}}> {data["nombre"]} ( {data["categoria"]}) </h2>
   
            
            <div className='sm:mx-20 lg:flex 2xl:mx-40'>
                <div className='grid grid-cols-1 gap-2 mx-5 mt-0 xl:gap-x-3 xl:gap-y-0'>
                    <div>
                         <div className='lg:flex'>
                            <div className='lg:mr-4'>
                                <img className='w-50' src={mainImage} alt="" />
                            </div>
                            <div>
                                <p className='font-bold text-xl my-4' style={{color: '#45595b'}}>{data["moneda"]} {data["price"]} </p>
                                <ul>
                                    <li className='fa fa-star' style={{color: '#ffb524'}}></li>
                                    <li className='fa fa-star' style={{color: '#ffb524'}}></li>
                                    <li className='fa fa-star' style={{color: '#ffb524'}}></li>
                                    <li className='fa fa-star' style={{color: '#ffb524'}}></li>
                                    <li className='fa fa-star' style={{color: '#45595b'}}></li>
                                </ul>
                               
                                 <div className='my-6'>
                                    <button className='bg-gray-100 rounded-full px-2 py-2'><i className='fa fa-minus'></i></button>
                                    <input className='w-16 text-center outline-none' type="text" value={1} />
                                    <button className='bg-gray-100 rounded-full px-2 py-2'><i className='fa fa-plus'></i></button>
                                </div>
                               
                               
                                <div className='border rounded-full border-orange-400 py-2 px-2 bg-[#29a329] lg:px-5 text-white hover:text-white hover:bg-[#1a1aff]'  >
                                    <span className='text-base pr-4 lg:pr-1 ' ><i className='fa fa-shopping-cart'></i></span>
                                    <button onClick={() => dispatch(addToCart(data))} className='text-xs ' >Agregar </button>
                                </div>
                                <div className="my-5 px-4 py-3 border  border-orange-500">
                                <img className='w-28 h-24 hover:cursor-pointer' src={data["imagen"]} alt="" onMouseEnter={() => handleMouseEnter(data["imagen"])}/>
                            </div>
                                <div className="my-5 px-4 py-3 border  border-orange-500">
                                <img className='w-28 h-24 hover:cursor-pointer' src={data["imagen2"]} alt="" onMouseEnter={() => handleMouseEnter(data["imagen2"])}/>
                            </div>
                            <div className="my-5 px-4 py-3 border border-orange-500">
                                <img className='w-28 h-24 hover:cursor-pointer' src={data["imagen3"]} alt="" onMouseEnter={() => handleMouseEnter(data["imagen3"])}/>
                            </div>
                            </div>
                        </div>
                        <div>
                        <p className='my-5' style={{color: '#45595b'}}>{data["descripcion"]}</p>
                        <div>
                        <h2 className='text-2xl font-bold my-6' style={{color: '#45595b'}}>Deje su comentario</h2>
                        <form action="">
                            <div className='mt-8 flex flex-col lg:flex-row'>
                                <input className='border-b-2 outline-none border-gray-200 w-full rounded-b-md px-2 '
                                 type="text" placeholder='Nombre' id="name"
                                 name="name"
                                 value={formData.name}
                                 onChange={handleChange}/>

                                <input className='border-b-2 outline-none border-gray-200 w-full rounded-b-md px-2 my-6 lg:my-0 lg:ml-4'
                                 type="email"  id="email"
                                 name="email"
                                 value={formData.email}
                                 onChange={handleChange}  placeholder='Email' />
                            </div>
                            <textarea className='w-full mt-4 border-b-2 outline-none border-gray-200 px-2 rounded-b-md' id="message"
          name="message"
          value={formData.message}
          onChange={handleChange} placeholder='Comentario' cols="30" rows="3"></textarea>
                        
                        <div className='my-10'>
                            <div className='flex justify-between'>
                                <span className='text-lg' style={{color: '#45595b'}}>Evalue:</span>
                                <ul>
                                    <li className='fa fa-star text-sm' style={{color: '#45595b'}}></li>
                                    <li className='fa fa-star text-sm' style={{color: '#45595b'}}></li>
                                    <li className='fa fa-star text-sm' style={{color: '#45595b'}}></li>
                                    <li className='fa fa-star text-sm' style={{color: '#45595b'}}></li>
                                    <li className='fa fa-star text-sm' style={{color: '#45595b'}}></li>
                                </ul>
                            </div>
                            
                            <div className='border rounded-full border-orange-400 w-44 text-center py-2 px-4 lg:px-2 '  style={{backgroundColor: '#003399'}}>
                                    <span className='text-base pr-4 lg:pr-1 ' style={{color: '#fff'}}><i className='fa fa-shopping-cart'></i></span>
                                    <button  type="button" onClick={() => handleSubmit()} className='text-xs text-white' style={{color: '#fff'}}>Enviar Comentario</button>
                                </div>
                        </div>
                        </form>
                    </div>
                            <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
                                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content" data-tabs-active-classes="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500" data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" role="tablist">
                                    <li className="me-2" role="presentation">
                                        <button className="inline-block p-4 border-b-2 border-orange-400 rounded-t-lg" style={{color: '#45595b'}} id="profile-styled-tab" data-tabs-target="#styled-profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Comentarios</button>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div id="default-styled-tab-content">

                                <div className=" p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="styled-profile" role="tabpanel" aria-labelledby="profile-tab">
                                { dataCom.length === 0 ? "sin comentario" :
                                dataCom.map((val) => {

                                return (
                                    <>
                                   
                                   
                                     <div >
                                     <h5 className="text-sm text-gray-900 dark:text-gray-300 mb-4">{val.nombre} - {val.fechaRegistro} <ul>
                                    <li className='fa fa-star' style={{color: '#ffb524'}}></li>
                                    <li className='fa fa-star' style={{color: '#ffb524'}}></li>
                                    <li className='fa fa-star' style={{color: '#ffb524'}}></li>
                                    <li className='fa fa-star' style={{color: '#ffb524'}}></li>
                                    <li className='fa fa-star' style={{color: '#45595b'}}></li>
                                </ul></h5>
                                    </div>
                                    <p className="text-sm text-gray-500 dark:text-gray-400 mb-4"> { val.comentario }   </p>
                                    <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
                                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content" data-tabs-active-classes="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500" data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" role="tablist">
                                    
                                    
                                </ul>
                            </div>
                                    </>

                                )
                                })}

                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className='mx-6'>
                    
                    <div>
                    <div className='relative w-96 max-w-full md:w-full'>
                            <div className='relative'>
                                <img className='w-full relative' src='../img/esp_oferta1.png' alt="" />
                            </div>
                            
                        </div>
                        <h2 className='text-xl font-semibold mt-6' style={{color: '#45595b'}}>OFERTAS</h2>
                        <div>
                        {dataO.slice(0,3).map((val) => {
                                return (
                                    <div className='flex flex-start items-center my-3' key={val.id}>

                                        <div>
                                            <img className='w-28 h-24' src={val.imagen} alt="" />
                                        </div>

                                        <button onClick={ () => handleShowDetail(val.idProducto)} >

                                        <div className='ml-4 justify-center items-center hover:cursor-pointer'>
                                        <span className='text-xl font-bold ml-2 ' style={{color: '#ff0000 '}}>-{val.descuento}%</span>
                                            
                                            <h4 className='text-md font-semibold main-text' >{val.nombre}</h4>
                                            <ul className='flex'>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#45595b'}}>{val.star}</li>
                                            </ul>
                                            <div className='lg:flex lg:flex-col'>
                                                <span className='text-xl font-bold' style={{color: '#009900'}}>{val.moneda} {val.price}</span>
                                          </div>
                                        </div>

                                        </button>


                                    </div>
                                )
                            })}
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className='lg:mx-20'>
                <h2 className='mx-5 text-3xl font-bold mt-10 mb-4' style={{color: '#45595b'}}>Productos relacionados</h2>
                <div className='grid grid-cols-1 gap-2 mx-5 mt-10 md:grid-cols-2 md:gap-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-3 xl:gap-y-0 '>
                {dataR.slice(0,4).map((val) => {
                                return (
                                    <div className='flex flex-start items-center my-3' key={val.id}>
                                        <div>
                                            <img className='w-28 h-24' src={val.imagen} alt="" />
                                        </div>
                                        <div className='ml-4 justify-center items-center'>
                                            <h4 className='text-md font-semibold' style={{color: '#45595b'}}>{val.nombre}</h4>
                                            <ul className='flex'>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#ffb524'}}>{val.star}</li>
                                                <li style={{color: '#45595b'}}>{val.star}</li>
                                            </ul>
                                            <div className='lg:flex lg:flex-col'>
                                               
                                                <span className='text-xl font-bold ml-2 ' style={{color: '#dc3545 '}}>{val.moneda} {val.price}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                </div>
            </div>
        </div>
    )
}

export default ShopDetail
