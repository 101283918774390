import React from 'react'
import WhatsAppButton from '../WhatsAppButton';
const Footer = () => {
    return (
        <>
        <div>
       
        <WhatsAppButton phoneNumber="+50576336310" message="Hola, estoy interesado en tus productos." />
      </div>
        <div className='mt-40 px-6 py-10 sm:px-20 md:px-24 lg:px-16 xl:px-20' style={{backgroundColor: '#132639'}}>
            <div className='py-10 lg:grid lg:grid-cols-3 lg:items-center xl:flex xl:justify-between '>
                <div>
                   
                    <div className='text-[#e69900] text-2xl sm:text-3xl font-extrabold logo_containerspan'>DTodo<span className=" text-[#e69900] ">Chic</span></div>
                    <p className='text-md' style={{color: '#ffb524'}}>Accesorios</p>
                </div>
               
                <ul className='flex justify-end'>
                    <li className='mx-1'><i className='fab fa-twitter py-3 px-3 social rounded-full border border-white'></i></li>
                    <li className='mx-1'><i className='fab fa-facebook-f  py-3 px-4 social rounded-full border border-white'></i></li>
                    <li className='mx-1'><i className='fab fa-youtube py-3 px-3 social rounded-full border border-white'></i></li>
                    <li className='mx-1'><i className='fab fa-linkedin-in py-3 px-3 social rounded-full border border-white'></i></li>
                </ul>
            </div>
            <hr className='w-10/12 lg:w-full m-auto h-0.5 mb-6'/>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-y-8 lg:grid-cols-4'>
                <div>
                    <h3 className='footer-head lg:w-40 xl:w-auto'>Porque comprar en nuestra Tienda!</h3>
                    <p className='footer-contact mt-4 md:w-60 lg:w-44 xl:w-56'>Las personas tienen acceso a accesorios de ultima tendencia, nuevos, de calidad y certificados, con gran atencion al cliente.</p>
                        </div>
                <div>
                    <h3 className='footer-head'>Info</h3>
                    <ul className='mt-5 '>
                        <li className='footer-body'>Nostros</li>
                        <li className='footer-body'>Contacto</li>
                        <li className='footer-body'>Politicas de Privacidad</li>
                        <li className='footer-body'>Terminos y condiciones</li>
                        <li className='footer-body'>Politicas de retorno</li>
                       
                    </ul>
                </div>
                <div>
                    <h3 className='footer-head'>Cuenta</h3>
                    <ul className='mt-5'>
                        <li className='footer-body'>Mi cuenta</li>
                        <li className='footer-body'>Detalle de compras</li>
                        <li className='footer-body'>Carrito de compras</li>
                        
                    </ul>
                </div>
                <div>
                    <h3 className='footer-head'>Contacto</h3>
                    <div className='mt-5'>
                        <p className='footer-contact'>Direccion: Managua</p>
                        <p className='footer-contact'>Email: frankball4@gmail.com</p>
                        <p className='footer-contact'>Telefono: +505 7633 6510</p>
                        <p className='footer-contact'>Pagos aceptados</p>
                        <img className='mt-4' src="../img/payment.png" alt="" />
                    </div>
                </div>
            </div>
            <hr className=' my-6'/>
            <div className='m-auto text-center'>
                <p className='font-medium' style={{color: '#ffffff80'}}><strong style={{color: '#ffccff'}}>&copy;DTodoChic, </strong>Todos los derechos reservados 2024</p>
            </div>
        </div>
        </>
    )
}

export default Footer
