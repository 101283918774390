import React from 'react'
import {
    BrowserRouter as Router,
    // Switch,
    Route,
    Routes,
    // Link
} from "react-router-dom";
import NavbarResp from '../common/header/NavbarResp'
import Home from '../Home';
import Footer from '../common/footer/Footer';
import Shop from '../Shop';
import ShopDetail from '../ShopDetail';
import Testimonial from '../testimonial/Testimonial';
import TestimonialMain from '../testimonial/TestimonialMain';
import Error from '../Error';
import Contact from '../Contact';
import Cart from '../Cart';
import Checkout from '../Checkout';

import Login from '../login';

import Register from '../Register';


const Pages = () => {
    return (
        <>
            <Router>
                <NavbarResp />
                <span class="pt-16 inline-block bg-teal-400"> </span>
                <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/productos" element={<Shop />}></Route>
                    <Route path="/detalle/:id"  element={<ShopDetail />}></Route>
                    <Route path="/testimonial" element={<TestimonialMain />}></Route>
                    <Route path="/carrito" element={<Cart />}></Route>
                    <Route path="/pagar" element={<Checkout />}></Route>
                    <Route path="/error" element={<Error />}></Route>
                    <Route path="/contacto" element={<Contact />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/registro" element={<Register />}></Route>

                </Routes>
                <Footer />
            </Router>

        </>
    )
}

export default Pages
