import React from "react";
import Back from "./common/Back";
import { useSelector, useDispatch } from 'react-redux';
import { addToCart,  removeFromCart ,clearItem ,clearCart  } from '../redux/CartSlice';

export default function Cart() {
    const dispatch = useDispatch();
    const items = useSelector(state => state.cart.items);
    const subtotal = useSelector(state => state.cart.subtotal);
    const total = useSelector(state => state.cart.total);

    const emptyCartMsg = (
        <h4 className="container mx-auto text-center py-4 text-2xl font-semibold" style={{color: '#45595b'}}>Su carrito de compras esta Vacio.</h4>
    );

    console.log(items);

    return (
        <>
        <Back title='Carrito'/>
        {items.length === 0 ? (
            emptyCartMsg
        ) : (
            <div className=" mx-10 lg:mx-24">
                 <div className='border rounded-full border-orange-400 w-fit px-6 py-4 mt-4'  style={{backgroundColor: '#b30000'}}>
                                    <span className='text-base pr-4 lg:pr-1 ' style={{color: '#fff'}}><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                    <button onClick={() => dispatch(clearCart())} className='text-base text-white font-semibold' style={{color: '#fff'}}>Vaciar Carrito</button>
                 </div>
                
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Productos
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Nombre
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Precio
                                </th>
                                <th scope="col" className="px-6 py-3">
                                   Cantidad 
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    opcion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr key={item.id} className="border-b">
                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        <img className="img-cart rounded-full" src={item.imagen} alt={item.nombre} />
                                    </td>
                                    <td className="px-6 py-4">
                                        {item.nombre}
                                    </td>
                                    <td className="px-6 py-4">
                                    {item.precio.split('/***/')[1]==1?"$ ":"C$ "}   {item.precio.split('/***/')[0]}
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="flex items-center">
                                            <button onClick={() => dispatch(removeFromCart(item))} className="bg-gray-100 rounded-full px-3 py-2">
                                                <i className="fa fa-minus"></i>
                                            </button>
                                            <p className="w-6 text-center mx-2">{item.quantity}</p>
                                            <button onClick={() => dispatch(addToCart(item))} className="bg-gray-100 rounded-full px-3 py-2">
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        ${parseFloat(item.precio.split('/***/')[0] * item.quantity).toFixed(2)}
                                    </td>
                                    <td className="px-6 py-4">
                                        <button onClick={() => dispatch(clearItem(item))} className="bg-gray-100 px-3 py-2 rounded-full">
                                            <i className="fa fa-times" style={{ color: '#dc3545' }}></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
               
                <div className="flex">
                    <div className="sm:w-10/12 lg:w-6/12 xl:w-8/12"></div>
                    <div className="mt-12 bg-gray-100 p-6 rounded-lg justify-end w-full lg:w-6/12">
                        <div style={{color: '#45595b'}}>
                            <h1 className="text-3xl font-bold"> Total</h1>
                            <div className="flex justify-between my-4">
                                <h2 className="text-xl font-semibold">Subtotal:</h2>
                                <span>${subtotal.toFixed(2)}</span>
                            </div>
                            <div className="flex justify-between mt-2 mb-6">
                                <h2 className="text-xl font-semibold">Envio</h2>
                                <div>
                                    <span>Costo : </span>
                                    <span>$3.00</span>
                                </div>
                            </div>
                            <hr />
                            <div className="flex justify-between my-4">
                                <h2 className="font-semibold text-lg">Total</h2>
                                <span>${(total + 3).toFixed(2)}</span>
                            </div>
                            <hr />
                        </div>
                          <div className='border rounded-full border-orange-400 w-fit px-6 py-4 mt-4'  style={{backgroundColor: '#004d00'}}>
                                    <span className='text-base pr-4 lg:pr-1 ' style={{color: '#fff'}}><i class="fa fa-money" aria-hidden="true"></i></span>
                                    <button  className='text-base text-white font-semibold' style={{color: '#fff'}}>Finalizar compra</button>
                        </div>
                    </div>
                </div>
            </div>
        )} 
        </>
  );
}